






































































































































































import Component, { mixins } from 'vue-class-component';
import WidgetMixins from '../../../mixins/WidgetMixins';
import DialogMixins from '../../../mixins/DialogMixins';
import { loadWidget } from '@/utils/helpers';
import { Getter, namespace } from 'vuex-class';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { IChangePassword, IUser } from '@/types/types';
const auth = namespace('auth');
extend('required', {
  ...required,
  message: `Field is required`,
});
@Component({
  name: 'Index',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    ValidationProvider,
    ValidationObserver,
  },
  filters: {},
})
export default class Index extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;
  @auth.Getter('getUserDetails') details!: IUser;

  changePassword: IChangePassword = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  updatePassword(): void {
    if (
      this.changePassword.newPassword !== this.changePassword.confirmPassword
    ) {
      this.$store.dispatch('snackBarVisibility', true, { root: true });
      this.$store.dispatch('snackBarMessage', `Password doesn't match.`, {
        root: true,
      });
      return;
    }
    this.$store.dispatch('auth/changePassword', this.changePassword);
  }
}
